<template>
  <div>
    <div @mousewheel="goWheel($event)">
      <!-- <div class="homeCode">
      <div class="homeCode-child Mini">
        <span class="hover-line"></span>
        <img :src="Mini" alt="">
        <span>小程序</span>
        <div class="Mini-code">
          <img :src="MiniCode" alt="">
        </div>
      </div>
      <div class="homeCode-child Android">
        <span class="hover-line"></span>
        <img :src="Android" alt="">
        <span>安卓</span>
        <div class="Android-code">
          <img :src="code" alt="">
        </div>
      </div>
      <div class="homeCode-child Apple">
        <span class="hover-line"></span>
        <img :src="Apple" alt="">
        <span>IOS</span>
        <div class="Apple-code">
          <img :src="code" alt="">
        </div>
      </div>
    </div> -->
      <el-carousel :style="{height:swiperHeight}" indicator-position="none" ref="swiper" @change="change" :autoplay="false" :loop="false" direction="vertical" @mousewheel="goWheel($event)" class="introduce">
        <!-- <el-carousel-item v-for="item, index in LostCardImgList" :key="index" class="introduceTop zIndex" v-lazy:background-image="item.imgUrl"></el-carousel-item> -->

        <el-carousel-item class="introduceTop zIndex">
          <div class="carousel_1">
            <img src="../../assets/home/lostCard/lostCardBg_1.png" alt="">
          </div>
        </el-carousel-item>
        <el-carousel-item class="introduceTop zIndex">
          <div class="carousel_2">
            <img src="../../assets/home/lostCard/lostCardBg_2.png" alt="">
          </div>
        </el-carousel-item>
        <el-carousel-item class="introduceTop zIndex">
          <div class="carousel_3">
            <img src="../../assets/home/lostCard/lostCardBg_3.png" alt="">
          </div>
        </el-carousel-item>
        <el-carousel-item class="introduceTop zIndex">
          <div class="carousel_4">
            <img src="../../assets/home/lostCard/lostCardBg_4.png" alt="">
          </div>
        </el-carousel-item>
        <el-carousel-item class="introduceTop zIndex">
          <div class="carousel_5">
            <img src="../../assets/home/lostCard/lostCardBg_5.png" alt="">
          </div>
        </el-carousel-item>
        <el-carousel-item class="introduceTop zIndex">
          <div class="carousel_6">
            <img src="../../assets/home/lostCard/lostCardBg_6.png" alt="">
          </div>
        </el-carousel-item>

        <!-- <el-carousel-item class="introduceTop zIndex">
        <div class="carousel_7">
          <img src="../../assets/home/lostCard/lostCardBg_7.png" alt="">
        </div>
      </el-carousel-item> -->
      </el-carousel>
    </div>

    <div class="carousel_7_div" v-if="this.swiperIndex == 6">
      <img src="../../assets/home/lostCard/lostCardBg_7.png" alt="">
    </div>

  </div>
</template>

<script>
export default {
  data() {
    return {
      swiperHeight: document.body.clientHeight - 52 + 'PX',
      swiperIndex: 0,
      one: true,    //以此来控制每次轮播图切换的张数
      LostCardImgList: [
        { imgUrl: this.$imgUrl + 'LostCard/lostCardBg_1.png' },
        { imgUrl: this.$imgUrl + 'LostCard/lostCardBg_2.png' },
        { imgUrl: this.$imgUrl + 'LostCard/lostCardBg_3.png' },
        { imgUrl: this.$imgUrl + 'LostCard/lostCardBg_4.png' },
        // { imgUrl: this.$imgUrl + 'LostCard/lostCardBg_5.png' },
        // { imgUrl: this.$imgUrl + 'LostCard/lostCardBg_6.png' },
      ],

      // lostCardBg_1: require('../../assets/home/lostCard/lostCardBg_1.png'),
      // lostCardBg_2: require('../../assets/home/lostCard/lostCardBg_2.png'),
      // lostCardBg_3: require('../../assets/home/lostCard/lostCardBg_3.png'),
      // lostCardBg_4: require('../../assets/home/lostCard/lostCardBg_4.png'),
      // lostCardBg_5: require('../../assets/home/lostCard/lostCardBg_5.png'),
      // lostCardBg_6: require('../../assets/home/lostCard/lostCardBg_6.png'),

    }
  },
  mounted() {
    const that = this
    window.onresize = () => {
      return (() => {
        window.fullHeight = document.documentElement.clientHeight;
        that.swiperHeight = (window.fullHeight - 52) + 'PX'
      })()

    }
  },
  methods: {
    change(e) {
      this.swiperIndex = e
      // console.log('啊啊', e);
    },

    goWheel(event) {
      if (event.deltaY > 0 && this.one == true) {
        this.$refs.swiper.next();
        this.one = false;
        setTimeout(() => {
          this.one = true
        }, 500)
        // console.log(this.swiperIndex, "22222")
        if (this.swiperIndex == 5) {
          this.swiperIndex = 6
        }
        if (this.swiperIndex == 6) {
          this.$emit("goEnd", true)
        } else {
          this.$emit("goEnd", false)

        }
      }

      if (event.deltaY < 0 && this.one == true) {
        this.$refs.swiper.prev();
        this.one = false;
        setTimeout(() => {
          this.one = true
        }, 500)
        this.$emit("goEnd", false)
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.homeCode {
  z-index: 999;
  position: absolute;
  right: 30px;
  top: 102px;
  width: 38px;
  height: 225px;
  border-radius: 27px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  // justify-content: space-around;
  align-items: center;
  // box-shadow: 0 0 15px #a8ced4;
  box-shadow: 0 0 15px #a8ced4;

  .homeCode-child {
    display: flex;
    flex-direction: column;
    align-items: center;

    img {
      width: 21px;
      height: 21px;
    }

    span {
      color: #7bc8d2;
      font-size: 7px;
      font-family: SourceHanSansCN-Medium;
    }
  }

  .homeCode-child:hover {
    cursor: pointer;
  }

  .Mini {
    margin-top: 43px;
    .hover-line {
      width: 2px;
      height: 30px;
      background-color: #92d5de;
      border-radius: 1px;
      position: absolute;
      right: 0;
      display: none;
    }
    .Mini-code {
      display: none;
      position: absolute;
      top: 20px;
      right: 50px;
      width: 70px;
      height: 70px;
      border-radius: 10px;
      background-color: #fff;
      box-shadow: 0 0 15px #a8ced4;
      overflow: hidden;

      img {
        width: 60px;
        height: 60px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }
  .Mini:hover .Mini-code,
  .Mini:hover .hover-line {
    display: block;
  }
  .Android {
    margin: 30px 0;
    .hover-line {
      width: 2px;
      height: 30px;
      background-color: #92d5de;
      border-radius: 1px;
      position: absolute;
      right: 0;
      display: none;
    }
    .Android-code {
      display: none;
      position: absolute;
      top: 80px;
      right: 50px;
      width: 70px;
      height: 70px;
      border-radius: 10px;
      background-color: #fff;
      box-shadow: 0 0 15px #a8ced4;

      img {
        width: 60px;
        height: 60px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }
  .Android:hover .Android-code,
  .Android:hover .hover-line {
    display: block;
  }
  .Apple {
    margin-bottom: 43px;
    .hover-line {
      width: 2px;
      height: 30px;
      background-color: #92d5de;
      border-radius: 1px;
      position: absolute;
      right: 0;
      display: none;
    }
    .Apple-code {
      display: none;
      position: absolute;
      top: 150px;
      right: 50px;
      width: 70px;
      height: 70px;
      border-radius: 10px;
      background-color: #fff;
      box-shadow: 0 0 15px #a8ced4;

      img {
        width: 60px;
        height: 60px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }
  .Apple:hover .Apple-code,
  .Apple:hover .hover-line {
    display: block;
  }
}
/deep/ .el-carousel__container {
  height: 100% !important;
}

.introduce {
  // min-width: 1680PX;
  .zIndex {
    z-index: 50;
  }
  .imgZIndex {
    img {
      z-index: -1 !important;
    }
  }
  .introduceDailyData {
    width: 100%;
    height: 100%;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    img {
      // width: 1390PX;
      // height: 797PX;
      z-index: 50;
      position: relative;
    }
  }
  .introduceTop {
    width: 100%;
    height: 100%;
    background-color: #fff;
    // position: relative;
    // overflow: hidden;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    // background-image: url("http://testshop.ichongapp.com/Introduce/bg.png");
    img {
      width: 100%;
      height: 100%;
      z-index: 50;
      position: relative;
    }

    .carousel_3 {
      background-color: #ec6949;
      img {
        width: 100%;
        height: 100%;
        transform: scale(0.9);
      }
    }

    .carousel_4 {
      background-color: #e8f5f7;
      img {
        width: 100%;
        height: 100%;
        transform: scale(0.8);
      }
    }

    .carousel_5 {
      transform: scale(0.7);
      background-color: #fff;
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        width: 100%;
        height: 100%;
      }
    }

    // .carousel_6 {
    //   width: 100%;
    //   height: 100%;
    //   background-color: #53aebb;
    //   display: flex;
    //   justify-content: center;
    //   align-items: center;

    //   img {
    //     width: 100%;
    //     height: 100%;
    //   }
    // }

    .carousel_6 {
      background-color: #53aebb;
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        width: 100%;
        height: 100%;
        transform: scale(0.7);
        position: relative;
        bottom: 55px;
      }
    }

    .carousel_7 {
      width: 100%;
      height: 100%;
      background-color: #53aebb;
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        width: 50%;
        height: 100%;
      }
    }
  }

  .carousel_7 {
    height: 200%;
  }
  .introduceKnowledge {
    position: relative;
    width: 100%;
    height: 100%;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    // img {
    //   width: 687PX;
    //   height: 513PX;
    // }
  }
  .dotStyleOne {
    .dot1 {
      border-radius: 50%;
      background-color: rgba($color: #92d5de, $alpha: 0.2);
      position: absolute;
      width: 500px;
      height: 500px;
      left: -100px;
      bottom: -200px;
      animation: dot 8s ease-in-out infinite;
      animation-delay: -2s;
    }
    .dot:last-child {
      border-radius: 50%;
      background-color: rgba($color: #92d5de, $alpha: 0.2);
      position: absolute;
      width: 500px;
      height: 500px;
      right: -200px;
      top: -200px;
      animation: dot 8s ease-in-out infinite;
      animation-delay: -2s;
    }
  }

  .dotStyleTwo {
    .dot:nth-child(2) {
      border-radius: 50%;
      background-color: rgba($color: #92d5de, $alpha: 0.2);
      position: absolute;
      width: 450px;
      height: 450px;
      right: -200px;
      bottom: -100px;
      animation: dot 8s ease-in-out infinite;
      animation-delay: -2s;
    }
    .dot:last-child {
      border-radius: 50%;
      background-color: rgba($color: #92d5de, $alpha: 0.2);
      position: absolute;
      width: 450px;
      height: 450px;
      left: -200px;
      top: -100px;
      animation: dot 8s ease-in-out infinite;
      animation-delay: -2s;
    }
  }
}
@keyframes dot {
  0%,
  100% {
    transform: translateX(-5%) translateY(0);
  }
  25% {
    transform: translateX(0) translateY(-5%);
  }
  50% {
    transform: translateX(5%) translateY(0);
  }
  75% {
    transform: translateX(0) translateY(5%);
  }
}

.carousel_7_div {
  width: 100%;
  height: 100%;
  background-color: #e8f5f7;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 88%;
    height: 100%;
  }
}
</style>